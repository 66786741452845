import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

const host = process.env.GATSBY_HOST;

const processes = {
  header: {
    title: 'processesHeaderTitle',
    subTitle: 'processesHeaderSubTitle',
    image: 'process.png',
    button1: {
      text: 'registration',
      subText: 'registrationSubText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
      event: 'PROC_get_started',
      id: 'sign_up_processes'
    },
    button2: {
      text: 'processesHeaderButton',
      link: '/form/live-demo',
      event: 'PROC_request_demo',
      id: 'request_demo_processes'
    }
  },
  content: [
    {
      title: 'processesContentTimeTitle',
      subTitle: 'processesContentTimeSubTitle',
      image: 'rocket.svg',
    },
    {
      title: 'processesContentDashTitle',
      subTitle: 'processesContentDashSubTitle',
      image: 'Dashboard.svg',
    },
    {
      title: 'processesContentTestTitle',
      subTitle: 'processesContentTestSubTitle',
      image: 'Tests.svg',
    },
    {
      title: 'processesContentProtoTitle',
      subTitle: 'processesContentProtoSubTitle',
      image: 'Prototype.svg',
    }
  ],
  demo: {
    event: 'PROC_digital_core_start'
  }
};


const Processes = (props) => (
  <Layout {...props}>
    <Landing
      data={processes}
      page={'processes'}
    />
  </Layout>
);

export default withIntl(Processes);

